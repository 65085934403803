<template>
  <auth>
    <template #auth-title>
      2-factor authentication
    </template>

    <template #form>
      <b-field
        label="We've sent a confirmation code to your email. Please enter it bellow."
        class="mb-4"
      >
        <b-input
          v-model="auth.code"
          v-cleave="{delimiters: ['-'], blocks: [3, 3], rawValueTrimPrefix: true}"
          icon="two-factor-authentication"
          type="text"
          placeholder="XXX-XXX"
          @input.native="onInput"
          @keyup.native.enter="signIn()"
        />
      </b-field>
    </template>

    <template #auth-additional-options />

    <template
      v-if="isConfirmationCodeInvalid"
      #error-message
    >
      The code you entered is incorrect. Please try again.
    </template>

    <template #action-button>
      <b-button
        expanded
        type="is-primary"
        @click="signIn()"
      >
        Confirm and sign in
      </b-button>
    </template>

    <template #info>
      <a @click="resendCode()">If you need a new code sent, please click here.</a>
      <b-message
        v-if="isCodeResent"
        size="is-small"
        class="mt-3"
        type="is-success"
      >
        New code sent
      </b-message>
    </template>
  </auth>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import { useCookies } from '@/hooks/vueCookies';
import { useVueJwtDecode } from '@/hooks/vueJwtDecode';
import { useRouter } from '@/hooks/vueRouter';
import { useSession } from '@/hooks/vueSession';
import { sendConfirmationCodeService, signInService } from '@/services/auth-service/authRequests';
import Auth from './../components/Auth';
import cleave from '@/directives/cleave';

export default {
    components: {
        Auth
    },
    directives: {
        cleave
    },
    setup () {
        // compose hooks
        const session = useSession();
        const cookies = useCookies();
        const jwtDecode = useVueJwtDecode();
        const router = useRouter();

        /***** validate sign in logic start *****/
        const isConfirmationCodeInvalid = ref(false);
        const auth = reactive({
            code: ''
        });
        const onInput = (event) => {
            auth.code = event.target._vCleave.getRawValue();
        };
        const signIn = async () => {
            try {
                isConfirmationCodeInvalid.value = false;
                const { data: { data } } = await signInService({
                    client_id: localStorage.getItem('client_id'),
                    code: auth.code
                });
                session.start();
                session.set('jwt', data.token);
                const decodedJwt = jwtDecode.decode(data.token);
                session.set('role', decodedJwt.isr);
                session.set('email', decodedJwt.ist);
                session.set('id', decodedJwt.iid);
                session.set('full_name', decodedJwt.ifn);
                session.set('profile_pic', decodedJwt.ipp);
                cookies.set('shared_session', data.token, 86400 * 360, '/', 'nuclicore.com');
                cookies.set('shared_session_domain', window.location.protocol + '//' + window.location.hostname, 86400 * 360, '/', 'nuclicore.com');
                localStorage.removeItem('client_id');
                localStorage.removeItem('client_secret');
                await router.push('/applications');
            } catch (err) {
                console.error(err);
                // handle server error
                if (err?.response) {
                    isConfirmationCodeInvalid.value = true;
                }
            }
        };
        /** logic exposed to template */
        const exposedSignInLogic = {
            isConfirmationCodeInvalid,
            auth,
            onInput,
            signIn
        };
        /***** validate sign in logic end *****/

        /***** resend code logic start *****/
        const isCodeResent = ref(false);
        const resendCode = async () => {
            try {
                isCodeResent.value = false;
                await sendConfirmationCodeService({
                    client_id: localStorage.getItem('client_id'),
                    code: this.auth.code
                });
                isCodeResent.value = true;
            } catch (err) {
                console.error(err);
            }
        };
        /** logic exposed to template */
        const exposedResendCodeLogic = {
            isCodeResent,
            resendCode
        };
        /***** resend code logic end *****/

        return {
            ...exposedSignInLogic,
            ...exposedResendCodeLogic
        };
    }
};
</script>
