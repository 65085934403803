// @ts-check
import Cleave from 'cleave.js';
import { isEqual, isObject } from 'lodash';

/** @type {import('vue').DirectiveOptions} */
const vCleave = {
    bind(el, binding) {
        handleCleaveInsert(el, binding);
    },
    update: (el, binding) => {
        let input = el.querySelector('input');
        // if binded value is updated, renew cleave instance to maintain reactivity
        if (!isEqual(binding.value, binding.oldValue)) {
            handleCleaveDestroy(el);
            input = handleCleaveInsert(el, binding);
        }
        // @ts-ignore
        if (input._vCleave) {
            const event = new Event('input', {bubbles: true});
            setTimeout(function () {
                // @ts-ignore
                input.value = input._vCleave.properties.result;
                input.dispatchEvent(event);
            }, 100);
        }
    },
    unbind(el) {
        handleCleaveDestroy(el);
    }
};

/**
 * @param {HTMLElement} el 
 * @param {object} binding 
 */
const handleCleaveInsert = (el, binding) => {
    try {
        const input = el.querySelector('input');
        if (isObject(binding.value)) {
            if (Object.keys(binding.value).length) {
                // @ts-ignore
                input._vCleave = new Cleave(input, binding.value);
            }
        } else {
            throw `[v-cleave]: expected object, received ${binding.value}`;
        }
        return input;
    } catch (err) {
        console.error(err);
    }
};

/**
 * @param {HTMLElement} el
 */
const handleCleaveDestroy = (el) => {
    const input = el.querySelector('input');
    // @ts-ignore
    if (input._vCleave) {
        // @ts-ignore
        input._vCleave.destroy();
    }
};

export default vCleave;
